/* eslint-disable no-console, no-undef, consistent-return */

class ServiceWorkerHelper {
  static log(...args) {
    const styles = [
      'background-color: #34495e',
      'color: #ffffff',
      'border-radius: 3px',
      'padding: 3px 5px'
    ].join(';');

    console.log('%c[register-sw]', styles, ...args);
  }

  static get isSupported() {
    return 'serviceWorker' in window.navigator;
  }

  constructor() {
    if (ServiceWorkerHelper.instance) {
      return ServiceWorkerHelper.instance;
    }

    ServiceWorkerHelper.instance = this;

    return this;
  }

  async register() {
    if (!ServiceWorkerHelper.isSupported) {
      ServiceWorkerHelper.log('register', 'not supported');
      return;
    }

    if (this.wb) {
      ServiceWorkerHelper.log('register', 'already registered');
      return;
    }

    const { Workbox } = await import('workbox-window');

    this.wb = new Workbox('/sw.js', {
      scope: '/'
    });

    this.wb.addEventListener('waiting', () => this.wbWaitingHandler());

    try {
      const result = await this.wb.register();

      console.log(result);

      // if (result && navigator.serviceWorker && 'PushManager' in window) {
      //   this.showPushNotificationInfoPopup();
      // }
    } catch (error) {
      ServiceWorkerHelper.log('register-failure', error);
    }
  }

  async unregisterAll() {
    ServiceWorkerHelper.log('unregister all service workers');

    const registrations = await navigator.serviceWorker.getRegistrations();
    registrations.forEach((registration) => registration.unregister());
  }

  wbWaitingHandler() {
    this.wb.addEventListener('controlling', () => {
      console.log('Service Worker is now controlling the page');
      // console.log('WINDOW.LOCATION.RELOAD()');
      // setInterval(() => {
      //   if (!document.querySelector('#push-notifications-feature')) {
      //     window.location.reload();
      //   }
      // }, 1000);
    });

    this.wb.messageSW({
      type: 'SKIP_WAITING'
    });
  }

  // async showPushNotificationInfoPopup() {
  //   if (localStorage.getItem('push-notifications-feature-alert')) {
  //     return;
  //   }
  //
  //   const response = await fetch('/push-notifications-feature.html');
  //   const html = await response.text();
  //
  //   const style = document.createElement('link');
  //   style.rel = 'stylesheet';
  //   style.href = `/local/templates/alcon/assets/no-hash-css/bundles--push-notification.css?ver=${Math.random()}`;
  //
  //   const fr = document.createElement('div');
  //   fr.innerHTML = html;
  //
  //   document.body.appendChild(fr);
  //   document.head.appendChild(style);
  //
  //   localStorage.setItem('push-notifications-feature-alert', true);
  //
  //   window.onPushNotificationAlertClose = () => {
  //     const modal = document.querySelector('#push-notifications-feature');
  //     modal.parentNode.removeChild(modal);
  //   };
  // }
}

window.addEventListener('load', () => {
  const swHelper = new ServiceWorkerHelper();
  const isOnUnregisterPage = window.location.pathname.startsWith('/sw-unregister');

  const { userAgent } = window.navigator;
  const isLighthouse = userAgent.includes('Lighthouse') || userAgent.includes('Page Speed Insights');

  if (__ENV__ === 'production' && !isOnUnregisterPage && !isLighthouse) {
    swHelper.register();
  } else {
    swHelper.unregisterAll();
  }
}, false);
